import React, { useEffect, useState } from "react";
import {
    Modal,
    List,
    Empty,
    message,
    Skeleton
} from 'antd'
import Lsp from '../../models/Lsp'
import { Link } from "react-router-dom";
export default function ListLink(props) {
    const [dataSource, setDataSources] = useState([])
    const [loading, setLoading] = useState(false)
    const initializeData = async () => {
        try {
            setLoading(true)
            let lspModel = new Lsp();
            const result = await lspModel.getAll()
            if (result?.code === 200) {
                console.log(result)
                setDataSources(result?.data)
            }

            setLoading(false)
        } catch (e) {
            message.error('Failed to load List')
            setLoading(false)
        }

    }

    useEffect(() => {
        initializeData()
    }, [])
    return (
        <>
            <Modal
                title={'LSP Certificate Link'}
                footer={null}
                width={1000}
                open={true}
                grid={{
                    xs: 24,
                    sm: 24,
                    md: 24,
                    lg: 24,
                    xl: 24,
                    xxl: 24,
                }}
                onCancel={(e) => {
                    props.toogleSelected(null);
                }}
            >
                <List
                bordered
                loading={loading}
                    itemLayout="horizontal"
                    dataSource={dataSource}
                    renderItem={(item) => (
                        <List.Item>
                            <Skeleton loading={loading} active>
                                <List.Item.Meta
                                    title={<Link target={'_blank'} to={item?.link}>{item?.name}</Link>}
                                    description={<div dangerouslySetInnerHTML={{ __html: item?.description }} />}
                                />
                            </Skeleton>
                        </List.Item>
                    )}
                />
            </Modal>
        </>
    )

}
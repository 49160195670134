import React, { useState, useEffect } from "react";
import { GoogleLogin } from 'react-google-login';
import {
    Button,
    Divider,
    Modal,
    Row,
    message,
    Checkbox, Form, Input, Image,
    Typography,
    Col
} from 'antd'
import { useNavigate } from "react-router-dom";
import { gapi } from 'gapi-script';
import Auth from "../../models/Auth";
import logo from '../../assets/images/logo.png'
import ApiHelper from "../../utils/ApiHelper";

export default function RegisterForm({ close, displayRegister }) {

    const { Text } = Typography;
    const [isRegisterContent, setIsRegisterContent] = useState(false)
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()

    const onFinish = async (values) => {
        console.log('value of regist', values)
        if (values?.password != values?.passwordConfirmation) {
            message.error('Password confirmation and Password is not match!')
            return
        }

        try {
            let result = await Auth.signUp(values);
            console.log('result ', result)
            if (result?.code === ApiHelper.CODE_OK) {
                message.success('Successfully register')
                close(true)
                return;
            } else {
                message.error(ApiHelper.getErrorMessage(result));
                setLoading(false)
                return;
            }
        } catch (e) {
            console.log(e)
            setLoading(false)
            message.error(ApiHelper.DEFAULT_ERROR_MSG)
            return;
        }
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <>

            <Row gutter={[24, 0]} justify="space-around">
                <Col md={{ span: 24 }}
                >
                    <Form
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        layout="vertical"
                        className="row-col"
                    >
                        <Form.Item
                            className="email"
                            label="Email"
                            name="email"
                            rules={[
                                {
                                    required: true,
                                    message: "Masukan alamat email!",
                                },
                            ]}
                        >
                            <Input placeholder="Email" />
                        </Form.Item>

                        <Form.Item
                            className="Full Name"
                            label="Fullname"
                            name="fullanme"
                            rules={[
                                {
                                    required: true,
                                    message: "Please input your fullname!",
                                },
                            ]}
                        >
                            <Input placeholder="Fullname" />
                        </Form.Item>
                        <Form.Item
                            className="PhoneNumberInput"
                            label="Phone Number"
                            name="phone_number"
                            rules={[
                                {
                                    required: true,

                                    message: "Please input your phone number!",
                                },
                            ]}
                        >
                            <Input placeholder="Phone Number" />
                        </Form.Item>
                        <Form.Item
                            className="Password"
                            label="Password"
                            name="password"
                            rules={[
                                {
                                    required: true,
                                    message: "Please input your password!",
                                },
                            ]}
                        >
                            <Input.Password placeholder="Password" />
                        </Form.Item>
                        <Form.Item
                            className="Confirm Password"
                            label="Password Confirmation"
                            name="passwordConfirmation"
                            rules={[
                                {
                                    required: true,
                                    message: "Please input Password Confirmation!",
                                },
                            ]}
                        >
                            <Input.Password placeholder="Password Confirmation" />
                        </Form.Item>
                        <Form.Item>
                            <Button
                                loading={loading}
                                type="primary"
                                htmlType="submit"
                                style={{ width: "100%" }}
                            >
                                SIGN UP
                            </Button>
                        </Form.Item>
                        <Form.Item
                            wrapperCol={{
                                offset: 8,
                                span: 16,
                            }}
                        >
                        </Form.Item>
                        <p className="font-semibold text-muted">
                            Already have an account?{" "}
                            <a onClick={() => {
                                close(true)
                            }} href="javascript:;">Sign In here</a>
                        </p>
                    </Form>

                </Col>
            </Row>

        </>
    )
}

import { SearchOutlined } from "@ant-design/icons"
import { Col, DatePicker, Row } from "antd"
import Search from "antd/lib/input/Search"
import { useState } from "react"

export default function SearchBar({
    onSearch,
    extra,
    searchButton
}) {
    return (
        <>
            <Row className="mb-5" gutter={6}>
                <Col style={{flex:1}}>
                    <Search
                        enterButton={<SearchOutlined
                            style={{
                                fontSize: 16,
                                color: '#fff',
                            }}
                        />}
                        allowClear={true}
                        placeholder="Search by Classroom Information"
                        onSearch={onSearch}
                        style={{
                            width: '100%',
                        }}
                    />
                </Col>
                {
                    extra
                }

            </Row>
        </>
    )
}
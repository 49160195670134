import ApiRequest from "../utils/ApiRequest";
// import GlobalData from "../util/GlobalData";

export default class Auth {
    static signIn = async (body) => {
        return await ApiRequest.set(`/v1/auth/authorize`, "POST", body, false);
    } 
    static signUp = async (body) => {
        return await ApiRequest.set(`/v1/auth/register`, "POST", body, false);
    }
    static register = async (body) => {
        return await ApiRequest.set(`/v1/auth/register-google`, "POST", body, false);
    }
}
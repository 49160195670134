import React, { useEffect, useState } from "react"
import {
    Modal,
    Collapse,
    Skeleton
} from 'antd'
import '../../models/Help'
import Help from "../../models/Help";
export default function Maps(props) {
    let helpModel = new Help();
    const { Panel } = Collapse;
    const [loading, setLoading] = useState(false)
    const [dataSources, setDataSources] = useState([])
    const initializeData = async () => {
        setLoading(true)
        let result = await helpModel.getAll();
        if (result.code === 200) {
            console.log(result.data)
            setDataSources(result.data)
        }
        setLoading(false)
    }

    useEffect(() => {
        initializeData()
    }, [])
    return (
        <>
            <Modal onCancel={() => {
                props.toogleToolbar(null)
            }} footer={false} width={1200} title="Help Center" visible={true}>
                {
                    loading ? <Skeleton /> : <Collapse accordion>
                        {
                            dataSources?.map((x,index) => {
                                return (
                                    <>
                                        <Panel header={x.question} key={index}>
                                        <div dangerouslySetInnerHTML={{ __html: x?.answer }} />
                                        </Panel>
                                    </>
                                )
                            })
                        }

                    </Collapse>

                }


            </Modal>
        </>
    )
}

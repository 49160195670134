import { Viewer } from 'photo-sphere-viewer';
import { MarkersPlugin } from 'photo-sphere-viewer/dist/plugins/markers';
import ListClass from "../modules/classroom/ListClass";
import MyClass from "../modules/classroom/MyClass";
import ListMeeting from "../modules/auditorium/ListMeeting";
import React, { useCallback, useEffect, useState } from 'react';
import ListBook from '../modules/public_book/ListBook';
import { useNavigate } from 'react-router-dom';
import OurProgramModule from '../modules/program/OurProgram';
import inFoot from '../assets/icons/in.png'
import auditoriumIcon from '../assets/icons/Auditorium.png'
import Stage from '../modules/Stage';
import ListLink from '../modules/lsp/ListLink';
import ListMaterial from '../modules/library/ListMaterial';
import logo from '../assets/images/logo.png'
const PanoView = (props) => {
    const [selectedModule, setSelectedModule] = useState(null)
    const [newMarkers, setNewMarkers] = useState(null)
    const navigate = useNavigate();
    let { currentRooms, currentMarkers, allRooms, statesRoom } = props

    useEffect(() => {

    }, [selectedModule])
    useEffect(() => {
    }, [newMarkers])

    const updateRoom = (spherePlayerInstance, virtualTour, roam) => {

        setNewMarkers(roam.markers);
        spherePlayerInstance.setPanorama(roam.file_url, {
            transition: 4000,
            showLoader: true,
            defaultLong: roam?.default_longitude,
            defaultLat: roam?.default_latitude,

        })
        // spherePlayerInstance.setOption('defaultLong', roam?.default_longitude);


        virtualTour.clearMarkers()

        new Promise(function (resolve) {
            setTimeout(
                function (x) {

                    resolve(roam.markers);
                }, 2000);
        }).then((value) => {
            for (let _markers of value) {
                virtualTour.addMarker(
                    {
                        id: _markers.id,
                        longitude: _markers.longitude,
                        latitude: _markers.latitude,
                        clickEventOnMarker: true,
                        html: _markers.html,

                    },
                )
            }
        });



    }

    const sphereElementRef = React.useRef();

    useEffect(() => {

        let spherePlayerInstance = new Viewer({
            container: sphereElementRef.current,
            panorama: currentRooms?.file_url,
            navbar: false,
            defaultLong: currentRooms?.default_longitude,
            defaultLat: currentRooms?.default_latitude,
            plugins: [
                MarkersPlugin,
            ],

            defaultZoomLvl: 5,
            canvasBackground: 'transparent',
            loadingImg: logo
        }, []);


        spherePlayerInstance.once('ready', () => {

            let virtualTour = spherePlayerInstance.getPlugin(MarkersPlugin);



            for (let _markers of currentMarkers) {
                virtualTour.addMarker(
                    {
                        id: _markers.id,
                        longitude: _markers.longitude,
                        latitude: _markers.latitude,
                        clickEventOnMarker: true,
                        html: _markers.html,
                        scale: [1, 2.5],
                    },
                )
            }
            virtualTour.on('select-marker', (e, marker) => {
                if (marker.id == 'SAMPLE') {
                    virtualTour.removeMarker('SAMPLE')
                    return
                }
                let mapMarker = allRooms?.map((v) => {

                    return v.markers
                });
                let selectedMarker = [];
                for (var i = 0; i < mapMarker.length; i++) {

                    for (var j = 0; j < mapMarker[i].length; j++) {
                        selectedMarker.push(mapMarker[i][j])
                    }
                }
                selectedMarker = selectedMarker?.filter((v) => {

                    return v.id === marker.id
                });


                if (selectedMarker.length > 0) {


                    if (selectedMarker[0].type === 'link') {

                        for (let i = 0; i < allRooms.length; i++) {
                            let linked = allRooms[i];

                            if (selectedMarker[0].link_id == linked.id) {
                                updateRoom(spherePlayerInstance, virtualTour, linked)
                                break;
                            } else {
                                continue;
                            }
                        }

                    } else if (selectedMarker[0].type === 'action') {
                        setSelectedModule(selectedMarker[0].link_id)
                    } else if (selectedMarker[0].type === 'ref') {
                        window.open(selectedMarker[0].link_id, '_blank')

                    } else if (selectedMarker[0].type === 'ref-static') {
                        if (selectedMarker[0].id === 'MARKER-PARTNER-LSP') {
                            navigate(`/lsp`)
                        } else {
                            navigate(`/lift`)
                        }
                        window.location.reload();
                    }
                } else {
                    alert('comming soon')
                }

            });
        });
        // spherePlayerInstance.on('click', (e, data) => {
        //     let virtualTour = spherePlayerInstance.getPlugin(MarkersPlugin);
        //     virtualTour.addMarker(
        //         {
        //             id: 'SAMPLE',
        //             longitude: data.longitude,
        //             latitude: data.latitude,
        //             clickEventOnMarker: true,
        //             html: Stage.renderHtml('Meeting List', 'out'),
        //             scale: [1, 2.5],
        //         },
        //     )
        //     navigator.clipboard.writeText(`longitude: ${data.longitude}, latitude: ${data.latitude},`);
        //     console.log(`longitude: ${data.longitude}, latitude: ${data.latitude},`);
        // });
        return () => {
            spherePlayerInstance.destroy();
        };
    }, [])


    const getLoadModule = () => {
        if (selectedModule !== null) {
            if (selectedModule === 'LIST_CLASS') {
                return (
                    <ListClass
                        toogleSelected={selectedModule => setSelectedModule(null)}
                    />
                )
            } else if (selectedModule === 'MY_CLASS') {
                return (
                    <MyClass
                        toogleSelected={selectedModule => setSelectedModule(null)}
                    />
                )

            } else if (selectedModule === 'LIST_MEETING') {
                return (
                    <ListMeeting
                        toogleSelected={selectedModule => setSelectedModule(null)}
                    />
                )
            } else if (selectedModule === 'PUBLIC_BOOK') {
                return (
                    <ListBook
                        toogleSelected={selectedModule => setSelectedModule(null)}
                    />
                )

            } else if (selectedModule === 'OUR-PROGRAM') {
                return (
                    <OurProgramModule
                        toogleSelected={selectedModule => setSelectedModule(null)}
                    />
                )

            } else if (selectedModule === 'LSP-CERTIFICATE-LINK') {
                return (
                    <ListLink
                        toogleSelected={selectedModule => setSelectedModule(null)}
                    />
                )

            } else if (selectedModule === 'MATERIAL') {
                return (
                    <ListMaterial
                        toogleSelected={selectedModule => setSelectedModule(null)}
                    />
                )
            }
        }



    }
    return (
        <>

            {
                getLoadModule()
            }

            {
                <div ref={sphereElementRef} style={{ width: '100vw', height: '100vh' }} />

            }

        </>
    )
}

export default PanoView;
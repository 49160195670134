import ReceptionistImage from '../assets/pano/receptionmain.jpg'
import OfficeImage from '../assets/pano/bridge.JPG';
import partnerImage from '../assets/pano/Bridge_Partner resize.jpg';
import libraryImage from '../assets/pano/library-min.jpg';
import auditoriumImage from '../assets/pano/Auditorium.jpg';
import classroomImage from '../assets/pano/classroom.jpeg';

import inFoot from '../assets/icons/in.png'
import outFoot from '../assets/icons/exit.png'


//icon
import IconLsp from '../assets/icons/LSP.png';
import IconNews from '../assets/icons/News.png';
import IconBookOnline from '../assets/icons/BacaOnline.png';
import IconCorpu from '../assets/icons/corpu.png';
import IconListClass from '../assets/icons/classroom.png';
import IconMyClass from '../assets/icons/classroom.png';
import IconAuditorium from '../assets/icons/Auditorium.png';
import IconOurProgram from '../assets/icons/PenawaranProgram.png';
import IconBri from '../assets/icons/bri-logo.png';
export default class Stage {


    static dynamicSort(property) {
        var sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a, b) {
            /* next line works with strings and numbers, 
             * and you may want to customize it to your needs
             */
            var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
        }
    }


    static renderHtml(label, type, customImage, containerStyleId = '') {
        let _html;
        switch (type) {
            case 'in':
                _html = `<div id="${containerStyleId}" class="content-action"><p class="text-marker">${label}</p><button class="in-button"><img src="${inFoot}"></img></button></div>`;
                break;
            case 'out':
                _html = `<div id="${containerStyleId}" class="content-action"><button class="action-button"><img src="${outFoot}"></img></button> <p class="text-marker-action">Exit</p></div>`;
                break;
            case 'ref':
                _html = `<div id="${containerStyleId}" class="content-action"><button class="action-button"><img src="${customImage}"></img></button> <p class="text-marker-action">${label}</p></div>`;
                break;
            case 'custom':
                _html = `<div id="${containerStyleId}" class="content-action"><button class="action-button"><img src="${customImage}"></img></button> <p class="text-marker-action">${label}</p></div>`;
                break;
            default:
                _html = `<div id="${containerStyleId}" class="content-action"><button class="action-button"><img src="${outFoot}"></img></button> <p class="text-marker-action">${label}</p></div>`;
        }
        return _html
    }

    static getAllRooms = () => {
        let rooms = [
            {
                id: "RECEPTIONIST",
                name: "Lobby",
                type: "PANORAMA",
                display_order: 1,
                file_url: ReceptionistImage,
                default_longitude: 2.563464246393634,
                default_latitude: 0.006793465122612252,

                markers: [
                    {
                        id: 'MARKER-BRIDGE-OFFICE',
                        label: 'Bridge Office',
                        html: this.renderHtml('Bridge Office', 'in'),
                        longitude: 3.7239699194445266,
                        latitude: -0.13420059575906085,
                        type: 'link',
                        link_id: 'BRIDGE-OFFICE',

                        description: 'Pintu masuk menuju Bridge Office'
                    },
                    {
                        id: 'MARKER-OUR-PROGRAM',
                        label: 'Bridge Program',
                        html: this.renderHtml('Our Program', 'custom', IconOurProgram),
                        longitude: 2.5878198627369255, latitude: -0.10672371704738493,
                        type: 'action',
                        link_id: 'OUR-PROGRAM',

                        description: 'Program program bridge'
                    },
                    {
                        id: 'MARKER-OUR-PARTNER',
                        label: 'Client & Partnership',
                        html: this.renderHtml('Client & Partnership', 'in'),
                        longitude: 1.1182060266899014, latitude: -0.17133882363556774,
                        type: 'link', /* module || room */
                        link_id: 'PARTNER' /*goto link_room_id */,
                        description: 'Pintu Masuk Menuju Ruang Client & Partnership'
                    },
                    {
                        id: 'MARKER-RECEPTIONIST-EXIT',
                        label: 'Exit',
                        isExit: true,
                        html: this.renderHtml('', 'out'),
                        latitude: -0.051703968497976316,
                        longitude: 5.630084535547716,
                        type: "ref-static",
                        link_id: '/lift',
                        description: 'Pintu Keluar Menuju Lift'
                    }

                ]
            },
            {
                id: "PARTNER",
                name: "Partner",
                type: "PANORAMA",
                display_order: 2,
                file_url: partnerImage,
                default_longitude: "-80deg",
                default_latitude: "-10deg",
                markers: [
                    {
                        id: 'MARKER-PARTNER-CORPU',
                        label: 'BRI Corporate University',
                        html: this.renderHtml('BRI <br>Corporate University', 'custom', IconCorpu, 'MARKER-PARTNER-CORPU'),
                        longitude: 5.030219476083738, latitude: -0.1461576774826041,
                        type: 'ref',
                        link_id: 'https://www.instagram.com/bri.corpu/',
                        description: 'Info BRI Corporate University'
                    },
                    {
                        id: 'MARKER-PARTNER-BRI',
                        label: 'BRI',
                        html: this.renderHtml('BRI', 'custom', IconBri),
                        longitude: 4.486476576269068, latitude: -0.12607424544304968,
                        type: 'ref',
                        link_id: 'https://bri.co.id/',
                        description: 'Info BRI Certificate'
                    },
                    {
                        id: 'MARKER-PARTNER-LSP',
                        label: 'Lembaga Sertifikasi Profesi',
                        html: this.renderHtml('Bridge Partner <br>Certification Course', 'in'),
                        longitude: 1.43366559116839, latitude: -0.03307518640336382,
                        type: "ref-static",
                        link_id: '/lsp',
                        description: 'Pintu masuk Lembaga Sertifikasi Profesi'
                    },
                    // {
                    //     id: 'MARKER-PARTNER-LSP',
                    //     label: 'Lembaga Sertifikasi Profesi',
                    //     html: this.renderHtml('Bridge Partner <br>Certification Course', 'custom', IconLsp),
                    //     longitude: 1.5104277673808455, latitude: -0.09098104145492947,
                    //     type: 'action',
                    //     link_id: 'LSP-CERTIFICATE-LINK',
                    //     description: 'Pintu masuk Lembaga Sertifikasi Profesi'
                    // },
                    // {
                    //     id: 'MARKER-PUBLIC-BOOKS',
                    //     label: 'Bridge Partner Public book',
                    //     html: this.renderHtml('Bridge Partner <br>Public book', 'custom', IconBookOnline),
                    //     longitude: '40deg',
                    //     latitude: '0deg',
                    //     type: 'action',
                    //     link_id: 'PUBLIC_BOOK',
                    // },
                    {
                        id: 'MARKER-PARTNER-EXIT',
                        label: '',
                        html: this.renderHtml('', 'out'),
                        isExit: true,
                        longitude: 2.262518409697379, latitude: -0.12968826917791265,
                        type: 'link',
                        link_id: 'RECEPTIONIST',
                        description: 'Pintu keluar menuju Lobby'
                    },


                ]
            },

            {
                id: "BRIDGE-OFFICE",
                name: "Bridge Office",
                type: "PANORAMA",
                display_order: 3,
                file_url: OfficeImage,
                default_longitude: "-80deg",
                default_latitude: "-10deg",
                markers: [
                    {
                        id: 'MARKER-BRIDGE-NEWS',
                        label: 'Bridge News',
                        html: this.renderHtml('Our News', 'custom', IconNews),
                        longitude: '-110deg',
                        latitude: '-10deg',
                        type: "ref",
                        link_id: 'http://bridgeindonesia.id/news?lang=id',
                        description: 'Informasi Bridge News'
                    },

                    {
                        id: 'MARKER-BRIDGE-INSTAGRAM',
                        label: 'InstaBridge',
                        html: this.renderHtml('InstaBridge', 'custom', 'https://upload.wikimedia.org/wikipedia/commons/thumb/e/e7/Instagram_logo_2016.svg/2048px-Instagram_logo_2016.svg.png'),
                        longitude: "115deg",
                        latitude: "0deg",
                        type: "ref",
                        link_id: 'https://www.instagram.com/ptbridge/',
                        description: 'Informasi Instagram Bridge'
                    },
                    {
                        id: 'MARKER-BRIDGE-OUR-LIBRARY',
                        label: 'Bridge Library',
                        html: this.renderHtml('Bridge Library', 'in'),
                        longitude: 4.070890657335896, latitude: -0.07144937995338085,
                        type: "link",
                        link_id: 'LIBRARY',
                        description: 'Pintu masuk menuju Bridge Office'
                    },
                    {
                        id: 'MARKER-BRIDGE-CLASSROOM',
                        label: 'Classroom',
                        html: this.renderHtml('Bridge Classroom', 'in', null, 'MARKER-BRIDGE-CLASSROOM'),
                        longitude: 2.780869593504003, latitude: -0.21770264597245226,
                        type: "link",
                        link_id: 'CLASSROOM',
                        description: 'Pintu masuk menuju Bridge Classroom'
                    },
                    {
                        id: 'MARKER-BRIDGE-AUDITORIUM',
                        label: 'Auditorium',
                        html: this.renderHtml('Bridge Auditorium', 'in'),
                        longitude: 3.6446246344061493, latitude: -0.14529709504508626,
                        type: "link",
                        link_id: 'AUDITORIUM',
                        description: 'Pintu masuk menuju Auditorium'
                    },
                    {
                        id: 'MARKER-PUBLIC-BOOKS',
                        label: 'Public Books',
                        html: this.renderHtml('Bridge<br> Public Book', 'custom', IconBookOnline, 'MARKER-PUBLIC-BOOKS'),
                        longitude: 6.041490522356199, latitude: -0.21516082932239144,
                        type: 'action',
                        link_id: 'PUBLIC_BOOK',
                        description: 'Informasi Buku Umum dari Bridge'
                    },
                    {
                        id: 'MARKER-BRIDGE-EXIT',
                        label: 'Exit',
                        isExit: true,
                        html: this.renderHtml('', 'out'),
                        longitude: 5.3859106240307755, latitude: -0.0240530575394855,
                        type: "link",
                        link_id: 'RECEPTIONIST',
                        description: 'Pintu keluar menuju Lobby'
                    }
                ]
            },
            {
                id: "CLASSROOM",
                name: "Classroom",
                type: "PANORAMA",
                display_order: 4,
                file_url: classroomImage,
                default_longitude: 0.007296524894078952,
                default_latitude: 0.04062171088602251,
                markers: [
                    {
                        id: 'MARKER-CLASSROOM-LIST',
                        label: 'List Classroom',
                        html: this.renderHtml('List Class', 'custom', IconListClass),
                        longitude: 5.965936070796979, latitude: 0.009917203286323506,
                        type: 'action',
                        link_id: 'LIST_CLASS',
                        description: 'Informasi daftar kelas Bridge Classroom'
                    },
                    {
                        id: 'MARKER-CLASSROOM-MYCLASS',
                        label: 'My Class',
                        html: this.renderHtml('My Class', 'custom', IconMyClass),
                        longitude: 0.3281004664995468, latitude: 0.010574223615678946,
                        type: 'action',
                        link_id: 'MY_CLASS',
                        description: 'Informasi kelas peserta Bridge Classroom'
                    },
                    {
                        id: 'MARKER-CLASSROOM-EXIT',
                        label: 'My Class',
                        html: this.renderHtml('', 'out'),
                        longitude: 2.2898285476650146, latitude: 0.11027977308873793,
                        isExit: true,
                        type: 'link',
                        link_id: 'BRIDGE-OFFICE',
                        description: 'Pintu keluar menuju Bridge Office'
                    },
                ]
            },

            {
                id: "AUDITORIUM",
                name: "Auditorium",
                type: "PANORAMA",
                display_order: 5,
                file_url: auditoriumImage,
                default_longitude: 1.6007326430706441,
                default_latitude: -0.009590184285823478,
                markers: [
                    {
                        id: 'MARKER-AUDITORIUM-LIST',
                        label: 'List Meeting',
                        html: this.renderHtml('Meeting List', 'custom', IconAuditorium),
                        longitude: 1.6007326430706441, latitude: -0.009590184285823478,
                        type: 'action',
                        link_id: 'LIST_MEETING',
                        description: 'Informasi meeting room maupun webinar'
                    },
                    {
                        id: 'MARKER-AUDITORIUM-EXIT',
                        label: 'Exit Auditorium',
                        html: this.renderHtml('', 'out'),

                        longitude: 2.3434833864934657, latitude: -0.23429064963874513,
                        isExit: true,
                        type: 'link',
                        link_id: 'BRIDGE-OFFICE',
                        description: 'Pintu keluar menuju Bridge Office'
                    },

                ]
            },
            {
                id: "LIBRARY",
                name: "Library",
                type: "PANORAMA",
                display_order: 6,
                file_url: libraryImage,
                default_longitude: "-80deg",
                default_latitude: "-10deg",
                markers: [
                    {
                        id: 'MARKER-LIBRARY-EXIT',
                        label: 'Exit',
                        html: this.renderHtml('', 'out'),
                        longitude: 4.623917724665414, latitude: -0.13139326529962592,
                        isExit: true,
                        type: 'link',
                        link_id: 'BRIDGE-OFFICE',
                        description: 'Pintu keluar menuju Bridge Office'
                    },
                    {
                        id: 'MARKER-LIBRARY-LIST',
                        label: 'List Meeting',
                        html: this.renderHtml('My Library', 'custom', IconBookOnline),
                        longitude: 3.0917384475292753, latitude: -0.2952433655030433,
                        type: 'action',
                        link_id: 'MATERIAL',
                        description: 'Informasi Materi Bridge'
                    },
                    {
                        id: 'MARKER-LIBRARY-MAGAZINE',
                        label: 'Article Magazine',
                        html: this.renderHtml('Article Magazine', 'custom', IconBookOnline),
                        longitude: 2.3542462957258206, latitude: -0.07987875717872339,
                        type: 'ref',
                        link_id: 'https://bridgeindonesia.id/news?cat=Artikel',
                        description: 'Informasi Article Bridge'
                    },


                ]
            },
        ];
        console.log(rooms.sort(this.dynamicSort("display_order")))
        return rooms.sort(this.dynamicSort("display_order"));
    }

}
import React, { useEffect, useState } from 'react';
import liftImage from '../assets/pano/Lift_newlogo.jpg'
import { useNavigate } from 'react-router-dom';
import inFoot from "../assets/icons/in.png"
import IconLsp from "../assets/icons/LSP.png"
import ListLink from '../modules/lsp/ListLink';
export default function Lift() {
    const navigate = useNavigate()

    const [selectedModule, setSelectedModule] = useState(null)
    useEffect(() => {

    }, [selectedModule])
    const getLoadModule = () => {
        if (selectedModule === 'LINK') {
            return (
                <>
                    <ListLink
                        toogleSelected={selectedModule => setSelectedModule(null)}
                    ></ListLink>
                </>
            )
        }
    }
    return (
        <>
            {getLoadModule()}
            <img src={liftImage} className="container-img-lsp" />
            <div style={{ top: '50%', left: '43%', position: 'absolute' }}>
                <p style={{ position: 'relative', bottom: '4rem', textAlign: 'center' }} class="text-marker-raw">Lembaga Sertifikasi Profesi</p>
                <button style={{ position: "relative", left: "5em" }} onClick={(e) => {
                    setSelectedModule('LINK')
                }} className="action-button"><img style={{ width: "35px", height: "35px", objectFit: "cover" }} src={IconLsp}></img></button>
            </div>

            <div style={{ top: '58%', left: '77%', position: 'absolute' }}>
                <p style={{ position: 'relative', bottom: '10rem', textAlign: 'center', textShadow: '-1px -1px 3px black' }} class="text-marker-raw">Client & Partnership</p>
                <button style={{
                    position: 'relative',
                    left: '5rem'
                }} onClick={(e) => {

                    navigate('/main',{
                        state : 'PARTNER'
                    });
                }} className="raw-button"><img style={{ width: "60px", height: "60px", objectFit: "cover" }} src={inFoot}></img></button>
            </div>
        </>
    )
}



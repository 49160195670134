export default class ApiHelper {
    static APP_CODE_MEMBER_INVALID = 1000;
    static APP_CODE_TOKEN_INVALID = 1001;
    static APP_CODE_AUTH_INVALID = 1002;
    static APP_CODE_VALIDATE_DATA = 1003;

    static CODE_OK = 200;
    static CODE_UNKNOWN_ACTION = 301;
    static CODE_PARAM_EMPTY = 302;
    static CODE_BAD_REQUEST = 400;
    static CODE_UNAUTHORIZED = 401;
    static CODE_PAYMENT_REQUIRED = 402;
    static CODE_FORBIDDEN = 403;
    static CODE_NOT_FOUND = 404;
    static CODE_INT_SERVER_ERR = 500;
    static CODE_NOT_IMPLEMENTED = 501;
    static TOKEN = sessionStorage.getItem('token') || null
    static MEMBER_DATA = sessionStorage.getItem('member_data') || null
    static error_code = [
        {
            code: 200,
            description: 'OK',
            error_msg: ''
        },
        {
            code: 501,
            description: 'Internal Server Error',
            error_msg: 'Terjadi kesalahan server'
        },
        {
            code: 403,
            description: 'Forbidden',
            error_msg: 'Akses, ditolak harap melakukan login ulang'
        },
        {
            code: 401,
            description: 'Unauthorized',
            error_msg: 'Akses expired, harap login ulang'
        },
        {
            code: 400,
            description: 'Bad Request',
            error_msg: 'Harap periksa kembali'
        },

    ];

    static DEFAULT_ERROR_MSG = 'Terjadi kesalahan server'

    static getErrorMessage(result) {
        if (result?.error?.message) {
            return result?.error?.message
        } else {
            return this.DEFAULT_ERROR_MSG;
        }
    }

    static removeAllToken = () => {
        localStorage.clear()
        sessionStorage.clear()
    }

    static setAuthToken = (result) => {
        const member_data = result?.data.member_data
        sessionStorage.clear()
        localStorage.clear()
        sessionStorage.setItem('token', result?.data.token.access_token)
        sessionStorage.setItem('member', JSON.stringify(member_data))
        localStorage.setItem('member', JSON.stringify(member_data))
    }


}
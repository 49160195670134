import React, { useEffect, useState } from "react";
import {
    Modal,
    Card,
    Space,
    Tag,
    List,
    Row,
    Col,
    Tabs,
    Typography,
    Table,
    Button,
    Divider,
    message
} from 'antd'
import { ClockCircleOutlined, GlobalOutlined, DesktopOutlined, ArrowLeftOutlined } from '@ant-design/icons'
import MeetingModel from "../../models/Meeting"
import apiConfig from "../../utils/apiConfig";
export default function ListMeeting(props) {


    const { Column, ColumnGroup } = Table;
    const modelMeeting = new MeetingModel();
    const { Text } = Typography;
    const onChange = (key) => {
        setShowDetail(false)
        setDetailData(null)
        if (key === 'item-1') {
            initializeMeetingRoom(1)
        } else if (key === 'item-2') {
            initializeMeetingRoom(2)
        } else {
            initializeHistory()
        }
    };

    const [datailData, setDetailData] = useState(null)
    const [showDetail, setShowDetail] = useState(false)
    const [loadingMeeting, setLoadingMeeting] = useState(false)
    const [loadingHistory, setLoadingHistory] = useState(false)
    const [meetingRooms, setMeetingRooms] = useState([]);
    const [histories, setHistories] = useState([]);

    const initializeMeetingRoom = async (type) => {
        setLoadingMeeting(true)
        let result = await modelMeeting.getListMeeting(type);
        if (result.code === 200) {
            setMeetingRooms(result.data)
        } else {
            message.error('Failed to load meeting list, please try again later')
        }
        setLoadingMeeting(false)
    }
    const initializeHistory = async (type) => {
        setLoadingHistory(true)
        let result = await modelMeeting.getListHistory();
        if (result.code === 200) {
            setHistories(result.data)
        } else {
            message.error('Failed to load history list, please try again later')
        }
        setLoadingHistory(false)
    }

    const renderHeader = () => {
        if (showDetail) {
            return (
                <>
                    <Button onClick={(e) => {
                        setShowDetail(false)
                        setDetailData(null)
                    }} type="link" shape="round" icon={<ArrowLeftOutlined />} size={'large'} /> Meeting List
                </>
            )
        } else {
            return 'Meeting List'
        }
    }


    const renderDetail = () => {
        return (
            <>
                <Card style={{ border: 'none' }} className='class-card'>
                    <div className="card-row">
                        <div className="class-left-card" style={{ width: '30%', marginRight: '10px' }}>
                            <img
                                style={{ width: '100%', height: '300px', objectFit: 'cover', paddingRight: '25px' }}
                                alt="example"
                                src={apiConfig.fileDir + datailData?.main_image}
                            />
                        </div>
                        <div className="class-right-card">
                            <div className='class-title'>
                                <p className='title'>{datailData?.name}</p>
                            </div>
                            <div className='class-description'>
                                <div dangerouslySetInnerHTML={{ __html: datailData?.description }} />

                            </div>

                            <br></br>
                            <Row gutter={16} className="responsive-row-meeting">
                                <Col className="gutter-row" xs={24} sm={24} md={4} lg={4} xl={4}>
                                    <h5>Organizer</h5>
                                </Col>
                                <Col className="gutter-row responsive-space-title-meeting" xs={24} sm={24} md={20} lg={20} xl={20}>
                                    {datailData?.organizer}
                                </Col>
                                <Col className="gutter-row" xs={24} sm={24} md={4} lg={4} xl={4}>
                                    <h5>Via</h5>
                                </Col>
                                <Col className="gutter-row responsive-space-title-meeting" xs={24} sm={24} md={20} lg={20} xl={20}>
                                    {datailData?.provider}
                                </Col>
                                <Col className="gutter-row" xs={24} sm={24} md={4} lg={4} xl={4}>
                                    <h5>Code</h5>
                                </Col>
                                <Col className="gutter-row responsive-space-title-meeting" xs={24} sm={24} md={20} lg={20} xl={20}>
                                    {datailData?.code}
                                </Col>
                                <Col className="gutter-row" xs={24} sm={24} md={4} lg={4} xl={4}>
                                    <h5>Tipe</h5>
                                </Col>
                                <Col className="gutter-row responsive-space-title-meeting" xs={24} sm={24} md={20} lg={20} xl={20}>
                                    <Tag color="rgb(216 38 9)">{datailData?.type == 1 ? 'Meeting Room' : 'Webinaar'}</Tag>
                                </Col>
                            </Row>

                            <Row>

                                <Col style={{ marginBottom: '20px', marginTop: '30px' }} xs={24} sm={24} md={12} lg={6} xl={6}>
                                    {
                                        datailData.is_enabled ?
                                            <Button onClick={(e) => {
                                                window.open(`${datailData.url}`, "_blank") || window.location.replace(`${datailData.url}`)
                                            }} type="primary" block size={'large'}>Go to Meeting</Button>
                                            : 'Meeting Has been over'
                                    }


                                </Col>
                            </Row>
                        </div>
                    </div>
                </Card>
            </>
        )
    }

    useEffect(() => {
        initializeMeetingRoom(1)

    }, [])

    let renderElement = () => {
        return (
            <>
                <List
                    grid={{
                        gutter: 16,
                        xs: 1,
                        sm: 2,
                        md: 3,
                        lg: 4,
                        xl: 4,
                        xxl: 3,

                    }}
                    className="demo-loadmore-list"
                    loading={loadingMeeting}
                    dataSource={meetingRooms}
                    renderItem={(v) => (
                        <>

                            <List.Item>
                                <Card
                                    onClick={(e) => {
                                        setShowDetail(true);
                                        setDetailData(v)
                                    }}
                                    bordered={true} hoverable cover={<img alt="example" style={{ height: '200px', objectFit: 'cover' }} src={apiConfig.fileDir + v.main_image} />}>

                                    <h5>{v.name}</h5>
                                    <Divider></Divider>
                                    <div className="row-profile">
                                        <div className="label-profile">
                                            <Text strong><ClockCircleOutlined className="secondary-icon" /> </Text>
                                        </div>
                                        <div className="content-profile">
                                            {v.meeting_time_format}
                                        </div>

                                    </div>
                                    <div className="row-profile">
                                        <div className="label-profile">
                                            <Text strong><GlobalOutlined className="secondary-icon" /> </Text>
                                        </div>
                                        <div className="content-profile">
                                            {v.organizer}
                                        </div>

                                    </div>
                                    <div className="row-profile">
                                        <div className="label-profile">
                                            <Text strong><DesktopOutlined className="secondary-icon" /> </Text>
                                        </div>
                                        <div className="content-profile">
                                            {v.provider}
                                        </div>

                                    </div>

                                    {/* <div className="mt-4">
                                                        <Button onClick={(e) => {
                                                            window.open(v.url, "_blank")
                                                        }} block disabled={!v.is_enabled}>Join Now</Button>
                                                    </div> */}



                                </Card>

                            </List.Item>


                        </>
                    )}>


                </List>

            </>
        )
    }

    const columnsHistory = [

        {
            title: 'type',
            dataIndex: 'type',
            render: (typeMeeting) => {
                console.log(typeMeeting == 1)
                return typeMeeting == 1 ? 'Meeting Room' : 'Webinar'
            },
        },
        {
            title: 'Name',
            dataIndex: 'name',
        },


        {
            title: 'Organizer',
            dataIndex: 'organizer',
        },
        {
            title: 'Status',
            dataIndex: 'is_enabled',
            render: (x) => {
                return x.is_enabled ? 'OnGoing' : 'Finished'
            }
        },
        {
            title: 'Meeting Date',
            dataIndex: 'meeting_time_format',
        },

        {
            title: 'File Meeting',
            dataIndex: '',
            key: 'x',
            render: (x) => {
                console.log(x)
                return x.history_file ? <Button onClick={(e) => {
                    window.open(`${x.history_file}`, "_blank") || window.location.replace(`${x.history_file}`)
                }} type="link">Download File</Button> : 'Download unavailable'
            },
        },
    ];
    return (
        <>
            <Modal
                title={renderHeader()}
                footer={null}
                width={1200}
                visible={true}
                onCancel={(e) => {
                    props.toogleSelected(null)
                }}
            >

                <Tabs
                    defaultActiveKey="item-1"
                    onChange={onChange}
                >
                    <Tabs.TabPane tab="Meeting Room" key="item-1">
                        {showDetail ? renderDetail() : renderElement()}
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Webinar" key="item-2">
                        {showDetail ? renderDetail() : renderElement()}
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Histori" key="item-3">
                        <Table loading={loadingHistory} columns={columnsHistory} dataSource={histories} />
                    </Tabs.TabPane>
                </Tabs>


            </Modal>
        </>
    )
}
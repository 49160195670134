
import { Switch, Route } from "react-router-dom";
import React from 'react';
import './assets/styles/globals.css';
import './assets/styles/main.css';
import './assets/styles/fixed-global.css';
import 'photo-sphere-viewer/dist/plugins/markers.css';
import "antd/dist/antd.css";
import './assets/styles/light-theme.css';
import { GoogleOAuthProvider } from '@react-oauth/google';
import Router from './routes';
function App() {
  return (
    <GoogleOAuthProvider clientId="576585272185-t60gfu8g20vvenda06c8i9osok59f6p0.apps.googleusercontent.com">
      <Router />
      </GoogleOAuthProvider>
  );
}

export default App;

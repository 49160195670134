import React, { useEffect, useState } from "react";
import {
    Modal,
    Carousel,
    Skeleton
} from 'antd'
import Program from '../../models/Program'
import apiConfig from "../../utils/apiConfig";
import { LeftCircleFilled, LeftOutlined, RightCircleFilled, RightOutlined } from "@ant-design/icons";
export default function OurProgramModule(props) {
    let helpModel = new Program();
    const [loading, setLoading] = useState(false)
    const [dataSources, setDataSources] = useState([])
    const initializeData = async () => {
        setLoading(true)
        let result = await helpModel.getAll();
        if (result.code === 200) {
            console.log(result.data)
            setDataSources(result.data)
        }
        setLoading(false)
    }

    useEffect(() => {
        initializeData()
    }, [])
    return (
        <>
            <Modal
                footer={null}
                width={1000}
                visible={true}
                onCancel={(e) => {
                    props.toogleSelected(null);
                }}
            >
                {
                    loading ? <Skeleton /> :
                        <Carousel arrows prevArrow={<LeftCircleFilled />} nextArrow={<RightCircleFilled />} autoplay={false}>
                            {
                                dataSources?.map((x, index) => {
                                    return (
                                        <>
                                            <div key={index}>
                                                <a onClick={(e) => {
                                                    let url = 'https://api.whatsapp.com/send/?phone=6281383315136&text&type=phone_number&app_absent=0';
                                                    window.open(`${url}`, "_blank") || window.location.replace(`${url}`)
                                                }} href="javascript:;">
                                                    <img alt={x.name} style={{ width: '100%', objectFit: 'cover' }} src={apiConfig.fileDir + x.main_image}></img>
                                                </a>

                                            </div>

                                        </>
                                    )
                                })
                            }
                        </Carousel>
                }






            </Modal>
        </>
    )

}
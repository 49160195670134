import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Stage from '../modules/Stage';
import ApiHelper from '../utils/ApiHelper';
import PanoView from './PanoView';
import Toolbar from './Toolbar';
import MainAudio from '../assets/audio/OrganizingLoop1.mp3'
import Sound from 'react-sound';
export default function Home() {
    const [currentRooms, setCurrentRooms] = useState(null);
    const [currentMarkers, setCurrentMarkers] = useState(null);

    const { state } = useLocation();
    console.log('isi state', state)
    useEffect(() => {
        if (state) {
            const filterState = Stage.getAllRooms().filter((v) => {
                return v.id === state
            })[0]
            setCurrentRooms(filterState);
            setCurrentMarkers(filterState?.markers)
        } else {
            setCurrentRooms(Stage.getAllRooms()[0])
            setCurrentMarkers(Stage.getAllRooms()[0].markers)
        }
        window.history.replaceState({}, document.title)
    }, [])
    const mapsRoom = () => {
        let allRooms = Stage.getAllRooms();
    }
    const fillSelectedRoom = (room) => {
        setCurrentRooms(room)
        setCurrentMarkers(room.markers)

    }

    const [soundPlay, setSoundPlay] = useState(false)
    useMemo(() => {
      setTimeout(() => {
        setSoundPlay(true)
      }, 3000)
    }, [])
    return (
        <>
            <Sound
                loop
                url={MainAudio}
                playStatus={soundPlay ? 'PLAYING' : 'STOPPED'}
                playFromPosition={300/* in milliseconds */}
                // onLoad={() => {
                //     setSoundPlay(true)
                // }}
            />
            {
                currentRooms ?
                    <>

                        <PanoView
                            statesRoom={state}
                            selectedRoom={currentRooms => fillSelectedRoom(currentRooms)}
                            currentRooms={currentRooms}
                            currentMarkers={currentMarkers}
                            allRooms={Stage.getAllRooms()}
                        ></PanoView>
                        <Toolbar
                            handlePlaying={(playAudio) => {
                                setSoundPlay(playAudio)
                            }}
                            soundPlay={soundPlay}
                            allRooms={Stage.getAllRooms()}
                        />
                    </>
                    : ''
            }
        </>
    )
}



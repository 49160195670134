import React, { useEffect, useState } from 'react';
import { MarkersPlugin } from 'photo-sphere-viewer/dist/plugins/markers';
import { Viewer } from 'photo-sphere-viewer';
import outsideImage from '../../assets/pano/outside.JPG'
import inFoot from '../../assets/icons/in.png'
import LoginForm from "../../components/core/LoginForm";
import { useNavigate } from 'react-router-dom';
import ApiHelper from '../../utils/ApiHelper';
import logo from '../../assets/images/logo.png'
import { Alert } from 'antd';

export default function SignIn() {
  const sphereElementRef = React.useRef();
  const [imagePreview, setImagePreview] = useState(outsideImage)
  const [displayLogin, setDisplayLogin] = useState(false)
  const navigate = useNavigate();
  useEffect(() => {
    let spherePlayerInstance = new Viewer({
      container: sphereElementRef.current,
      panorama: imagePreview,
      navbar: false,
      plugins: [
        MarkersPlugin,
      ],
      defaultZoomLvl: 1,
      canvasBackground: 'red',
      loadingImg: logo
    }, []);

    // add marker
    spherePlayerInstance.once('ready', () => {
      let virtualTour = spherePlayerInstance.getPlugin(MarkersPlugin);
      virtualTour.addMarker({
        id: 'hotspot-df',
        longitude: '10deg',
        latitude: '0deg',
        clickEventOnMarker: true,
        html: `<p class="text-marker">Masuk</p><button class="in-button"><img src="${inFoot}"></img></button>`,

      })
      virtualTour.on('select-marker', (e, marker) => {
        if (!ApiHelper.TOKEN) {
          setDisplayLogin(true)
        } else {
          navigate('/lift')
        }

        return false;
      });
    });
    return () => {
      spherePlayerInstance.destroy();
    };
  }, []);

  return (
    <>
      {displayLogin ? <LoginForm
        close={(is_cose) => {
        
          if (is_cose) {
            setDisplayLogin(false)
           window.location.reload()
          }
        }}
        displayLogin={displayLogin}
        toogleDisplay={displayLogin => setDisplayLogin(false)}
      >
      </LoginForm>
        : ''}
      <div ref={sphereElementRef} style={{ width: '100vw', height: '100vh' }} />
    </>
  )
}


import apiConfig from "./apiConfig";
import ApiHelper from "./ApiHelper";

export default class ApiRequest {
    static set = async (endpoint, method, body, is_auth = true) => {
        console.log('API ACCESS: ' + endpoint);
        let request = {
            method: method,
            headers: {
                'Content-Type': 'application/json',
                'Auth-Client-Id': process.env.REACT_APP_CLIENT_ID,
                'Auth-Client-Secret': process.env.REACT_APP_CLIENT_SECRET,
                Accept: 'application/json',
            },
            body: JSON.stringify(body)
        };

        if (is_auth) {
            Object.assign(request.headers, { 'Auth-Access-Token': ApiHelper.TOKEN })
        }

        let response = await fetch(apiConfig.base_url + endpoint, request);
        if (response.ok) {
            let exp_handle = await response.clone().json();
            if (exp_handle.code === ApiHelper.CODE_UNAUTHORIZED) {
                ApiHelper.removeAllToken()
            }
            return response.json();
        } 
        // else {
        //     ApiHelper.removeAllToken()
        //     return;
        // }
        // console.log('response', response)
        let error = await response.json();
        console.log(error, error)
        // if (error.msg === 'JWT_EXPIRED') {
        //     window.location.reload();
        //     throw error;
        // }


        throw error;
    }



    static setMultipart = async (endpoint, method, body, is_auth = true) => {
        console.log('isi body', body)

        let response = await fetch(apiConfig.base_url + endpoint, {
            method: method,
            headers: {
                'Auth-Client-Id': process.env.REACT_APP_CLIENT_ID,
                'Auth-Client-Secret': process.env.REACT_APP_CLIENT_SECRET,
            },
            body: body
        });
        console.log('responnya',response)
        if (response.ok) {
            return response.json()
        }
        else {
            let error = await response.json()
            console.log(error)
            ApiHelper.removeAllToken()
            throw error
        }
    }


}

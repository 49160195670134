import {
    Card,
    Select,
    Row,
    Col,
    Form,
    Divider,
    Space,
    Input,
    Modal,
    Button,
    List,
    Skeleton,
    Tag,


} from 'antd'
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import BookModel from "../../models/Book"
import apiConfig from '../../utils/apiConfig';
export default function ListBook(props) {
    let modelBook = new BookModel();
    const { Option } = Select;
    const { Search } = Input;
    const { Meta } = Card;
    const [initLoading, setInitLoading] = useState(true);
    const [loading, setLoading] = useState(false)
    const [books, setBooks] = useState([]);
    const [data, setData] = useState([]);
    const [datailData, setDetailData] = useState(null)
    const [showDetail, setShowDetail] = useState(false)
    const [count, setCount] = useState(0);
    const [filtered, setFilter] = useState('');
    const limit = 6;
    const initializeBook = async () => {
        setInitLoading(true)
        let result = await modelBook.getAll(filtered, 0);
        console.log(result)
        if (result.code === 200) {
            setBooks(result.data)
            setData(result.data)
        }
        setInitLoading(false)
    }
    useEffect(() => {
        initializeBook()
    }, [filtered])



    let onLoadMore = async () => {

        setLoading(true);
        setBooks(
            books?.concat(
                [...new Array(limit)].map(() => ({
                    loading: true,
                    title: {},
                })),
            ),
        );

        let result = await modelBook.getAll('', count + 1);
        if (result.code === 200) {
            const newData = data.concat(result.data);
            setBooks(newData)
            setData(newData)
        }
        let nextCount = count + 1;
        setCount(nextCount);
        setLoading(false);
    }

    const loadMore =
        !initLoading && !loading ? (
            <div
                style={{
                    textAlign: 'center',
                    marginTop: 12,
                    height: 32,
                    lineHeight: '32px',
                }}
            >
                <Button onClick={onLoadMore}>loading more</Button>
            </div>
        ) : null;

    const onSearch = (value) => {
        setFilter(value);
        initializeBook();
    };

    const renderHeader = () => {
        if (showDetail) {
            return (
                <>
                    <Button onClick={(e) => {
                        setShowDetail(false)
                    }} type="link" shape="round" icon={<ArrowLeftOutlined />} size={'large'} /> Public Books
                </>
            )
        } else {
            return 'Public Books'
        }
    }

    return (
        <>
            <Modal
                title={renderHeader()}
                footer={null}
                width={1200}
                visible={true}
                onCancel={(e) => {
                    props.toogleSelected(null)
                }}
            >
                {
                    showDetail ? <Card style={{ border: 'none' }} className='class-card'>
                        <div className="card-row responsive-book-container">
                            <div className="class-left-card" style={{ marginRight: '25px' }}>
                                <img
                                    style={{ width: '250px', height: '200px', objectFit: 'contain' }}
                                    alt="example"
                                    src={apiConfig.fileDir + datailData?.main_image}
                                />
                            </div>
                            <div className="class-right-card">
                                <div className='class-title'>
                                    <p className='title'>{datailData?.title}</p>
                                </div>
                                <div className='class-description'>
                                    <div dangerouslySetInnerHTML={{ __html: datailData?.description }} />

                                </div>

                                <br></br>
                                <Row gutter={16}>
                                    <Col className="gutter-row" span={5}>
                                        <h5>Author</h5>
                                    </Col>
                                    <Col className="gutter-row" span={24}>
                                        {datailData?.author}
                                    </Col>
                                    <Col className="gutter-row" span={5} style={{ marginTop: '20px' }}>
                                        <h5>Category</h5>
                                    </Col>
                                    <Col className="gutter-row" span={24}>
                                        <Tag color="rgb(14 30 110)">{datailData?.category_name}</Tag>
                                    </Col>
                                    {/* <Col className="gutter-row" span={4}>
                                    <h5>Batas Pendaftaran</h5>
                                </Col> */}
                                    {/* <Col className="gutter-row" span={8}>
                                    20 Agustus 2022
                                </Col> */}
                                </Row>

                                <Row>

                                    <Col style={{ marginBottom: '20px', marginTop: '30px' }} xs={24} sm={24} md={6} lg={6} xl={6}>
                                        {
                                            <Button onClick={(e) => {
                                                window.open(`${datailData.reference_link}`, "_blank") || window.location.replace(`${datailData.reference_link}`)
                                            }} type="primary" block size={'large'}>View Book</Button>
                                        }


                                    </Col>
                                </Row>
                            </div>


                        </div>
                    </Card> :
                        <>
                            <Form
                                name="basic"
                                autoComplete="off"
                                layout="vertical"

                            >
                                <Row>

                                    <Col span={18} offset={3}>
                                        <Form.Item
                                            name="title"

                                        >
                                            <Search
                                                placeholder="input search text"
                                                allowClear
                                                onSearch={onSearch}
                                            />
                                        </Form.Item>

                                    </Col>
                                    {/* <Col span={9} offset={1}>
                            <Form.Item
                                name="category"
                            >
                                <Select placeholder="Please Select Category" size="large">
                                    <Option value="1">IT</Option>
                                    <Option value="1">Marketing</Option>
                                    <Option value="1">Cooking</Option>
                                </Select>
                            </Form.Item>
                        </Col> */}
                                </Row>
                            </Form>


                            <List
                                grid={{
                                    gutter: 16,
                                    xs: 1,
                                    sm: 2,
                                    md: 4,
                                    lg: 4,
                                    xl: 4,
                                    xxl: 3,
                                }}
                                className="demo-loadmore-list"
                                loading={initLoading}
                                loadMore={loadMore}
                                dataSource={books}
                                renderItem={(item) => (

                                    <Skeleton title={false} loading={item.loading} active>
                                        <List.Item>
                                            <Card
                                                onClick={(e) => {
                                                    setShowDetail(true);
                                                    setDetailData(item)
                                                }}
                                                className='card-items'
                                                title={item.name}
                                                hoverable
                                                cover={<img className="book-image" alt="example" src={apiConfig.fileDir + "" + item.main_image} />}
                                            ><p style={{ fontWeight: 700 }}>{item.title}</p> <small>By {item.author}</small>  <div style={{ marginTop: '12px' }}><Tag color="rgb(14 30 110)">{item?.category_name}</Tag></div></Card>
                                        </List.Item>

                                    </Skeleton>

                                )}
                            />
                        </>
                }





            </Modal>
        </>
    )
}
import ApiRequest from "../utils/ApiRequest";
// import GlobalData from "../util/GlobalData";

export default class Book {
    getListMeeting = async (type) => {
        return await ApiRequest.set(`/v1/meeting/list-meeting?type=${type}`, "GET");
    }

    getListHistory = async () => {
        return await ApiRequest.set(`/v1/meeting/list-history`, "GET");
    }
}
import { ArrowLeftOutlined } from "@ant-design/icons"
import { Button, Modal } from "antd"

export default function CustomModal({ title, onClose, onBack, width, height, footer, content, showBack }) {
    const handleClose = () => {
        onClose()
    }


    const handleBack = () => {
        onBack()
    }

    const renderHeader = () => {
        return (
            showBack ?
                <>
                    <Button onClick={(e) => {
                        handleBack()
                    }} type="link" shape="round" icon={<ArrowLeftOutlined />} size={'large'} /> {title}
                </>
                : title )

    }


    return (
        <>
            <Modal
                title={renderHeader()}
                footer={footer ?? null}
                visible={true}
                width={width ?? 1200}
                onCancel={handleClose}
            >
                {content}
            </Modal>
        </>
    )
}
import { useEffect, useMemo } from 'react';
import { Navigate, useNavigate, useRoutes } from 'react-router-dom';
import MainContainer from './layouts/MainContainer';
import SignIn from './pages/auth/SignIn';
import Lift from './pages/Lift';
import Lsp from './pages/Lsp';
import ApiHelper from './utils/ApiHelper';
export default function Router() {

  const navigate = useNavigate()
  useEffect(() => {
    if (!ApiHelper.TOKEN) {
      ApiHelper.removeAllToken()
      navigate('/')
    }
  }, [])

  let routes = [
    {
      path: '/main',
      element: <MainContainer />,
    },
    { path: '/lift', element: <Lift /> },
    { path: '/lsp', element: <Lsp /> },
    { path: '/', element: <SignIn /> },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]


  return useRoutes(routes)

}

import {
    Row,
    Col,
    Form,
    Input,
    Modal,
    Button,
    Table,
    Image
} from 'antd'

import { useEffect, useState } from 'react';
import MaterialModel from "../../models/Material"
import apiConfig from '../../utils/apiConfig';
export default function ListMaterial(props) {
    let modelMaterial = new MaterialModel();
    const { Search } = Input;
    const [initLoading, setInitLoading] = useState(true);
    const [Materials, setMaterials] = useState([]);
    const [filtered, setFilter] = useState('');

    const initializeMaterial = async () => {
        setInitLoading(true)
        let result = await modelMaterial.getListMaterial(filtered);
        console.log('isi result', result)
        if (result.code === 200) {
            setMaterials(result.data)
        }
        setInitLoading(false)
    }
    useEffect(() => {
        initializeMaterial()
    }, [filtered])

    const onSearch = (value) => {
        setFilter(value);
        initializeMaterial();
    };


    const downloader = (x) => {
        console.log('isi file x : ', x)
        if (x?.file_url !== "" && x?.file_url !== null && x.file_url !== undefined) {
            return (
                <>
                    <Button onClick={(e) => {
                        window.open(`${apiConfig.fileDir + x.file_url}`, "_blank") || window.location.replace(`${apiConfig.fileDir + x.file_url}`)
                    }} type="link">Download File</Button>
                </>
            )
        } else if (x?.real_path_url !== "" && x?.real_path_url !== null && x.real_path_url !== undefined) {
            return (
                <>
                    <Button onClick={(e) => {
                        window.open(`${x.real_path_url}`, "_blank") || window.location.replace(`${x.real_path_url}`)
                    }} type="link">Download Files</Button>
                </>
            )
        } else {
            return 'Download Unavailable'
        }

    }

    const columnsMaterial = [

        {
            title: 'Name',
            dataIndex: '',
            responsive: ["xs","sm","md","lg","xl"],
            render: (x) => {

                return (
                    <>
                        <Image
                            width={150}
                            height={100}
                            src={`${apiConfig.fileDir + x.main_image}`}
                        />
                        <div style={{ marginTop: '20px' }}>{x.name}</div>
                    </>
                )
            },
        },


        {
            title: 'Description',
            dataIndex: 'description',
            responsive: ["sm","md","lg","xl"],
            render: (values => {
                return (
                    <div
                        dangerouslySetInnerHTML={{ __html: values }}
                    />
                )
            })
        },
        {
            title: 'Category',
            dataIndex: 'material_category_name',
            responsive: ["xs","sm","md","lg","xl"],
        },

        {
            title: 'File',
            dataIndex: '',
            key: 'x',
            responsive: ["xs","sm","md","lg","xl"],
            render: (x) => {
                return downloader(x)
            },
        },
    ];

    return (
        <>
            <Modal
                title={'Our Materials'}
                footer={null}
                width={1200}
                visible={true}
                onCancel={(e) => {
                    props.toogleSelected(null)
                }}
            >
                <>
                    <Form
                        name="basic"
                        autoComplete="off"
                        layout="vertical"

                    >
                        <Row>

                            <Col span={18} offset={3}>
                                <Form.Item
                                    name="title"

                                >
                                    <Search
                                        placeholder="input search text"
                                        allowClear
                                        onSearch={onSearch}
                                    />
                                </Form.Item>

                            </Col>

                        </Row>
                    </Form>
                    <Table loading={initLoading} columns={columnsMaterial} dataSource={Materials} />

                </>






            </Modal>
        </>
    )
}
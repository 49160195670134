import React, { useEffect, useMemo, useState } from "react"
import { UserOutlined, PhoneOutlined, GlobalOutlined, QuestionCircleOutlined, SoundFilled, LogoutOutlined } from '@ant-design/icons';
import {
    Button,
    Row,
    Col,
    Typography
} from 'antd'
import Maps from "../modules/toolbar/Maps";
import Help from "../modules/toolbar/Help";
// import Sound from "react-sound";
import Audio from "../assets/audio/main_audio.mp3"
import { Navigate, useNavigate } from "react-router-dom";
import ApiHelper from "../utils/ApiHelper";
import { FaVolumeMute, FaVolumeUp } from "react-icons/fa";
export default function Toolbar(props) {
    const { Text } = Typography;
    const { allRooms, handlePlaying, soundPlay } = props
    const navigate = useNavigate();
    const [barAction, setBarAction] = useState(null);
    useEffect(() => {

    }, [barAction])

    let getLoadToolbar = () => {
        if (barAction === 'ACTION_MAP') {
            return (
                <Maps
                    toogleToolbar={barAction => setBarAction(barAction)}
                />
            )
        }
        else if (barAction === 'ACTION_HELP') {
            return (
                <Help
                    toogleToolbar={barAction => setBarAction(barAction)}
                />
            )
        }
    }

    return (
        <>

            <div className='toolbar'>
                <div className='toolbar-content'>
                    <Row>
                        <Col span={24}>
                            <Row className="responsive-toolbar-container">
                                <Col span={4} style={{ textAlign: 'center' }}>
                                    <Button type="primary" onClick={(e) => {
                                        setBarAction('ACTION_MAP');
                                    }} shape="circle" icon={<GlobalOutlined />} size={'large'}></Button>
                                    <div style={{
                                        position: 'relative',
                                    }} className='toolbar-title'>
                                        <Text className='text'>Maps</Text>
                                    </div>
                                </Col>
                                <Col span={4} style={{ textAlign: 'center' }}>
                                    <Button onClick={(e) => {
                                        setBarAction('ACTION_HELP');
                                    }} type="primary" shape="circle" icon={<QuestionCircleOutlined />} size={'large'}></Button>
                                    <div style={{
                                        position: 'relative',
                                    }} className='toolbar-title'>
                                        <Text className='text'>Help</Text>
                                    </div>
                                </Col>
                                <Col span={4} style={{ textAlign: 'center' }}>
                                    <Button onClick={(e) => {
                                        handlePlaying(!soundPlay)
                                    }} type="primary" shape="circle" icon={<span role="img" aria-label="question-circle" className="anticon anticon-question-circle">{soundPlay ? <FaVolumeUp  /> : <FaVolumeMute />}</span>} size={'large'}></Button>
                                    <div style={{
                                        position: 'relative',
                                    }} className='toolbar-title'>
                                        <Text className='text'>Sound</Text>
                                    </div>
                                </Col>
                                <Col span={4} style={{ textAlign: 'center' }}>
                                    <Button onClick={(e) => {
                                        let url = 'https://api.whatsapp.com/send/?phone=6281383315136&text&type=phone_number&app_absent=0';
                                        window.open(`${url}`, "_blank") || window.location.replace(`${url}`)
                                    }} type="primary" shape="circle" icon={<PhoneOutlined />} size={'large'}></Button>
                                    <div style={{
                                        position: 'relative',
                                    }} className='toolbar-title'>
                                        <Text className='text'>Contact</Text>
                                    </div>
                                </Col>

                                <Col span={4} style={{ textAlign: 'center' }}>
                                    <Button onClick={(e) => {
                                        ApiHelper.removeAllToken()
                                        window.location.reload()
                                    }} type="primary" shape="circle" icon={<LogoutOutlined />} size={'large'}></Button>
                                    <div className='toolbar-title'>
                                        <Text className='text'>Logout</Text>
                                    </div>
                                </Col>

                            </Row>



                        </Col>
                    </Row>

                </div>
            </div >
            {getLoadToolbar()}
        </>
    )
} 
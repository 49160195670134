import ApiRequest from "../utils/ApiRequest";
// import GlobalData from "../util/GlobalData";

export default class Classroom {
    getListClassroom = async (dataSourceOptions) => {
        return await ApiRequest.set(`/v1/classroom/list-classroom?${new URLSearchParams(dataSourceOptions).toString()}`, "GET");
    }

    getMyClassroom = async (dataSourceOptions) => {
        return await ApiRequest.set(`/v1/classroom/my-classroom?${new URLSearchParams(dataSourceOptions).toString()}`, "GET");
    }
    getListHistory = async (id) => {
        return await ApiRequest.set(`/v1/classroom/history-classroom`, "GET");
    }

    static getPaymentInfo = async (id) => {
        return await ApiRequest.set(`/v1/classroom/payment-information?classroom_id=${id}`, "GET");
    }

    joinClass = async (body) => {
        return await ApiRequest.set(`/v1/classroom/join-classroom`, "POST", body);
    }
}
import { Card, Divider, Image,Button, Typography } from "antd"
import { useEffect } from "react"

export default function ClasroomCard({
    title,
    coverSrc,
    status,
    description,
    content,
    handleClick
}) {
    useEffect(() => {
        console.log(content)
    }, [])
    const Text = Typography
    return (
        <>
            <Card
                onClick={(e) => {
                    handleClick(true)
                }}
                bordered={true} hoverable cover={
                    <Image
                        style={{ objectFit: 'fill' }}
                        preview={false}
                        width={'100%'}
                        height={200}
                        src={coverSrc}
                    />}>
                <h5>{title}</h5>
                {description}   
                <Divider></Divider>
                {
                    content?.map((v) => {
                        if (v?.visible) {
                            return (
                                <>
                                    <div className="row-profile">
                                        <div className="label-profile">
                                            {v?.icon}
                                        </div>
                                        <div className="content-profile">
                                            {v?.label}
                                        </div>
                                    </div>
                                </>
                            )
                        }
                        return ''
                    })
                }
            </Card>
        </>
    )
}
import React, { useState, useEffect } from "react";

import {
    Button,
    Divider,
    Modal,
    Row,
    message,
    Checkbox, Form, Input, Image,
    Typography,
    Col,
    Spin
} from 'antd'
import jwt_decode from "jwt-decode";
import { Link, useNavigate } from "react-router-dom";
import { gapi } from 'gapi-script';
import Auth from "../../models/Auth";
import logo from '../../assets/images/logo.png'
import ApiHelper from "../../utils/ApiHelper";
import { GoogleLogin } from '@react-oauth/google';
import RegisterForm from "./RegisterForm";
export default function LoginForm({ close, displayLogin }) {
    const MODE_LOGIN = 0
    const MODE_REGISTER = 1
    const { Text } = Typography;
    const [mode, setMode] = useState(MODE_LOGIN)
    const [isRegisterContent, setIsRegisterContent] = useState(false)
    const [showLoaderRegisterGoogle, setShowLoaderRegisterGoogle] = useState(false)
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()



    const onFinished = async (values) => {

        try {
            setLoading(true)
            let result = await Auth.signIn(values);
            if (result?.code === ApiHelper.CODE_OK) {
                const member_data = result?.data.member_data
                ApiHelper.setAuthToken(result)
                message.success('Selamat Datang ' + member_data?.full_name)
                close(true)
                navigate('/lift')
                return;
            } else {
                message.error(ApiHelper.getErrorMessage(result));

            }
            setLoading(false)
            return;
        } catch (e) {
            console.log(e)
            setLoading(false)
            message.error(ApiHelper.DEFAULT_ERROR_MSG)
            return;
        }
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const registerGoogle = async (_goole_member_data) => {

        setShowLoaderRegisterGoogle(true);
        try {
            let body = {
                email: _goole_member_data?.email,
                full_name: _goole_member_data?.name

            }
            let result = await Auth.register(body)
            console.log('si result', result)
            if (result?.code === ApiHelper.CODE_OK) {
                const member_data = result?.data.member_data
                ApiHelper.setAuthToken(result)
                message.success('Selamat Datang ' + member_data?.full_name)
                close(true)

            } else {
                message.error(result?.message);
            }
            setShowLoaderRegisterGoogle(false)
            return;
        } catch (e) {
            console.log(e)
            setShowLoaderRegisterGoogle(false)
        }

    }

    useEffect(() => {
        function start() {
            gapi.client.init({
                clientId: "576585272185-r0b4e129f5u8artc0j37ufs6c4274h7k.apps.googleusercontent.com",
                scope: 'email',
            });
        }

        gapi.load('client:auth2', start);
    }, []);


    return (
        <>
            <Modal open={displayLogin} onCancel={() => {
                close(true)
            }}
                footer={[]}
            >

                <Row align="middle" justify="space-around">

                    <Col md={{ span: 24 }}
                    >


                        <Col style={{ textAlign: 'center', marginBottom: '20px' }}>
                            <Image
                                preview={false}
                                width={150}
                                objectFit="contain"
                                src={logo}
                            />
                        </Col>


                        <Col style={{ textAlign: 'center', marginBottom: '20px' }}>
                            <Text className="font-regular text-muted" level={5}>
                                Sign in to application
                            </Text>
                        </Col>
                        {
                            showLoaderRegisterGoogle ?
                                <Row style={{ justifyContent: 'center' }}>
                                    <Spin size="large" />
                                </Row>
                                :
                                <>
                                    {
                                        mode == MODE_LOGIN ?
                                            <>
                                                <Form
                                                    onSubmit={e => e.preventDefault()}
                                                    onFinish={onFinished}
                                                    onFinishFailed={onFinishFailed}
                                                    layout="vertical"
                                                    className="row-col"
                                                >
                                                    <Form.Item
                                                        className="email"
                                                        label="Email"
                                                        name="email"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: "Masukan alamat email!",
                                                            },
                                                        ]}
                                                    >
                                                        <Input placeholder="email" />
                                                    </Form.Item>

                                                    <Form.Item
                                                        className="username"
                                                        label="Password"
                                                        name="password"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: "Please input your password!",
                                                            },
                                                        ]}
                                                    >
                                                        <Input.Password placeholder="Password" />
                                                    </Form.Item>


                                                    <Form.Item>
                                                        <Button
                                                            type="primary"
                                                            htmlType="submit"
                                                            style={{ width: "100%" }}
                                                        >
                                                            SIGN IN
                                                        </Button>
                                                    </Form.Item>
                                                </Form>
                                                <p className="font-semibold text-muted">
                                                    Don't have an account?{" "}
                                                    <a onClick={() => {
                                                        setMode(MODE_REGISTER)
                                                    }} href="javascript:;">
                                                        Sign Up
                                                    </a>
                                                </p>
                                                <div className="text-center">
                                                    <Text>Or</Text>
                                                </div>
                                                <Divider></Divider>
                                                <Row className="mt-3" style={{ justifyContent: 'center', marginTop: '20px' }}>

                                                    <GoogleLogin
                                                        style={{ marginTop: '20px' }}
                                                        onSuccess={credentialResponse => {

                                                            console.log(credentialResponse)
                                                            let decoded = jwt_decode(credentialResponse?.credential);
                                                            if (decoded?.email) {
                                                                registerGoogle(decoded)
                                                            } else {
                                                                message.error('failed to connecting akun')
                                                            }
                                                        }}
                                                        onError={() => {
                                                            console.log('login failed');
                                                        }}
                                                    />
                                                </Row>
                                            </>
                                            : <RegisterForm
                                                close={(isSuccess) => {
                                                    if (isSuccess) {
                                                        setMode(MODE_LOGIN)
                                                    }
                                                }}
                                            />
                                    }

                                </>
                        }
                    </Col>
                </Row>


            </Modal>

        </>
    )
}

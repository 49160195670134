import React from 'react';
import liftImage from '../assets/pano/liftwithbanner.jpg'
import { useNavigate } from 'react-router-dom';
import inFoot from "../assets/icons/in.png"
import outFoot from '../assets/icons/exit.png'
export default function Lift() {
    const navigate = useNavigate()
    return (
        <>


            <div style={{ backgroundImage: `url(${liftImage})`, backgroundPosition:'center', backgroundRepeat:'no-repeat', backgroundSize:'cover', width:'100%', height:'100vh' }}>
                <div style={{ top: '64%', left: '20%', position: 'absolute' }}>
                    <p style={{ position: 'relative', bottom: '10rem', textAlign: 'center', lineHeight: 1 }} class="text-marker-raw">PT.Brilian Indah Gemilang</p>
                    <button style={{
                        textAlign: 'center',
                        position: 'relative',
                        left: '50px',
                        top: '2.7em'
                    }} onClick={(e) => {
                        navigate('/main');
                    }} className="raw-button"><img style={{ width: "60px", height: "60px", objectFit: "cover" }} src={inFoot}></img></button>
                </div>
                <div style={{ top: '75%', right: '15%', position: 'absolute' }}>
                    <p class="text-marker-raw">Exit</p>
                    <button style={{
                        textAlign: 'center',
                        position: 'relative',

                    }} onClick={(e) => {
                        navigate('/');
                    }} className="raw-button"><img style={{ width: "60px", height: "60px", objectFit: "cover" }} src={outFoot}></img></button>
                </div>
            </div>



        </>
    )
}



import React from "react"
import { UserOutlined, GlobalOutlined, QuestionCircleOutlined, SoundFilled } from '@ant-design/icons';
import {
    Button,
    // Card,
    Tabs,
    Modal,
    Row,
    Col,
    List,
    Avatar,
    Typography
} from 'antd'

import Stage from "../Stage";
export default function Maps(props) {
    let mappingStage = Stage.getAllRooms();
    const { Text } = Typography;
    return (
        <>
            <Modal onCancel={() => {
                props.toogleToolbar(null)
            }} footer={false} width={800} title="Bridge 360 Map" visible={true}>
                <Tabs
                    size={'large'}
                    tabPosition={'left'}
                >
                    {
                        mappingStage?.map((v, index) => {
                            return (
                                <>
                                    <Tabs.TabPane tab={v?.name} key={`item-${index}`}>

                                        <List
                                            itemLayout="horizontal"
                                            style={{ width: "100%" }}
                                        >
                                            {
                                                v?.markers.map((marker) => {
                                                    return (
                                                        <>
                                                            <Row gutter={16}>
                                                                <List.Item style={{ width: "100%" }}>
                                                                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                                                        <div className={`image-meta ${marker?.isExit ? 'exist-image-meta' : ''}`} dangerouslySetInnerHTML={{ __html: marker?.html }} />
                                                                    </Col>
                                                                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                                                        <List.Item.Meta
                                                                            title={<a href="https://ant.design"></a>}
                                                                            description={marker?.description}
                                                                        />
                                                                    </Col>
                                                                </List.Item>
                                                            </Row>

                                                        </>
                                                    )
                                                })
                                            }

                                        </List>

                                    </Tabs.TabPane>
                                </>
                            )
                        })
                    }




                </Tabs>


            </Modal>
        </>
    )
}

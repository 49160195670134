import React, { useEffect, useState } from "react";
import {
    Modal,
    Card,
    Button,
    Tag,
    Row,
    Col,
    Image,
    List,
    Typography,
    Badge,
    Divider,
    message,
    DatePicker,
    Empty,
    Skeleton,
    Tabs,
} from 'antd'
import { ClockCircleOutlined, UserOutlined, DesktopOutlined, ArrowLeftOutlined, ArrowDownOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import Classroom from "../../models/Classroom";
import apiConfig from "../../utils/apiConfig";
import ClassPaymentDialog from "./ClassPaymentDialog";
import SearchBar from "../../components/reusable/SearchBar";
import ClasroomCard from "../../components/reusable/ClassroomCard";
import CustomModal from "../../components/reusable/CustomModal";
export default function ListClass(props) {
    const { confirm } = Modal;
    const { Text } = Typography;
    let classroomModel = new Classroom();
    const [dataSources, setDataSources] = useState([]);
    const [showDetail, setShowDetail] = useState(false);
    const [detailData, setDetailData] = useState(null);
    const [loadingClass, setLoadingClass] = useState(false);
    const [loadingMore, setLoadingMore] = useState(false)
    const [totalPage, setTotalPage] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [showDialogPayment, setShowDialogPayment] = useState(false)
    const [dataSourceOptions, setDataSourceOptions] = useState({
        list_type: 'UPCOMING',
        keywords: '',
        page: 1,
        limit: 8
    })

    const initializeData = async (isLoadingMore = false) => {
        setLoadingClass(!isLoadingMore ? true : false)
        let result = await classroomModel.getListClassroom(dataSourceOptions);

        setTotalPage(result?.total_page)
        if (result.code === 200) {
            if (isLoadingMore) {
                const newData = dataSources.concat(result.data);
                setDataSources(newData)
            } else {
                setDataSources(result?.data)
            }

        }
        setLoadingMore(false)
        setLoadingClass(false)
    }

    const onLoadMore = () => {
        setLoadingMore(true)
        setCurrentPage(currentPage + 1)
        setDataSourceOptions(Object.assign(dataSourceOptions, { page: dataSourceOptions?.page + 1 }))
        initializeData(true)
    };
    const loadMore = !loadingClass && !loadingMore && totalPage > 0 && currentPage !== totalPage ? (
        <div
            style={{
                textAlign: 'center',
                height: 32,
                marginTop: '2em',
                lineHeight: '32px',
            }}
        >
            <Button type="link" style={{ position: 'relative', bottom: '1.5em' }} onClick={onLoadMore}>Show More <ArrowDownOutlined /></Button>
        </div>
    ) : null;

    useEffect(() => {
        initializeData();
    }, [])

    const { RangePicker } = DatePicker;
    const onSearch = (value) => {
        setCurrentPage(1)
        setTotalPage(0)
        setDataSourceOptions(Object.assign(dataSourceOptions, { page: 1 }))
        setDataSourceOptions(Object.assign(dataSourceOptions, { keywords: value }))
        initializeData()
    };

    useEffect(() => {
        initializeData();
    }, [])



    const joinToClass = async (id) => {

        try {
            let obj = {
                member_id: 1,
                classroom_id: id
            };
            console.log(JSON.stringify(obj))
            let result = await classroomModel.joinClass(obj);

            if (result.code === 200) {
                message.success('Successfully join classroom')
                props.toogleSelected(null)
            } else {
                message.error('Terjadi kesalahan server')
            }
            initializeData();
        } catch (e) {
            // console.log(e)
            message.error('Terjadi kesalahan server')
        }

    }
    const showConfirmJoinClass = (id) => {

        confirm({
            title: 'Apakah anda yakin akan bergabung di kelas ini?',
            icon: <ExclamationCircleOutlined />,
            content: '',
            okText: 'Bergabung',
            okType: 'primary',
            cancelText: 'Batal',

            onOk() {
                joinToClass(id)
            },

            onCancel() {
                console.log('Cancel');
            },
        });
    };


    const renderList = () => {
        return <>

            <SearchBar
                dataSourceOptions={dataSourceOptions}
                // extra={extraSearch()}
                onSearch={onSearch}
            />
            {
                !loadingClass && dataSources.length === 0 && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            }
            <List
                grid={{
                    gutter: 16,
                    xs: 1,
                    sm: 2,
                    md: 4,
                    lg: 4,
                    xl: 4,
                    xxl: 4,
                }}
                loadMore={loadMore}
                className="demo-loadmore-list"
                loading={loadingClass}
                dataSource={dataSources}
                renderItem={(value) => (
                    <>
                        {
                            dataSources.length > 0 &&
                            <List.Item>
                                <Badge.Ribbon style={{ display: !value?.classroom?.is_registered ? 'none' : 'block' }} color="volcano" text="Terdaftar">
                                    <ClasroomCard
                                        handleClick={(showDetail) => {
                                            if (showDetail) {
                                                setShowDetail(true);
                                                setDetailData(value)
                                            }
                                        }}
                                        title={value?.classroom?.name}
                                        coverSrc={apiConfig.fileDir + value?.classroom.main_image}
                                        content={[
                                            {
                                                icon: <Text strong><ClockCircleOutlined className="secondary-icon" /> </Text>,
                                                label: value?.classroom.class_time_format,
                                                visible: true
                                            },
                                            {
                                                icon: <Text strong><UserOutlined className="secondary-icon" /> </Text>,
                                                label: value?.instructor?.map((v) => {
                                                    return v?.full_name
                                                })?.join(', '),
                                                visible: value?.instructor?.length > 0 ? true : false
                                            },
                                            {
                                                icon: <Text strong><DesktopOutlined className="secondary-icon" /> </Text>,
                                                label: <Tag color="#87d068">Google Classroom</Tag>,
                                                visible: true
                                            }
                                        ]}
                                    />
                                </Badge.Ribbon>
                            </List.Item>

                        }
                    </>
                )}>
                <Skeleton title={false} loading={loadingMore} active>
                </Skeleton>

            </List></>

    }
    const renderDetail = () => {
        return (
            <>
                <Card bordered={false} className='class-card'>
                    {
                        <div className="card-row">
                            <div className="class-left-card">
                                <Image
                                    width={350}
                                    height={400}
                                    alt="example"
                                    src={apiConfig.fileDir + detailData.classroom.main_image}
                                />
                            </div>
                            <div className="class-right-card">
                                <div className='class-title'>
                                    <p className='title'>{detailData.classroom.name}</p>
                                </div>
                                <Divider style={{ color: '#212c7a' }}>Description</Divider>
                                <div className='class-description'>
                                    <div dangerouslySetInnerHTML={{ __html: detailData.classroom.description }} />

                                </div>

                                <br></br>
                                {
                                    detailData?.attachment?.length > 0 &&
                                    <>
                                        <Divider style={{ color: '#212c7a' }}>Material</Divider>
                                        <div className='class-description'>
                                            <Row className="mb-3">
                                                <Col md={4}>
                                                    <strong>Category</strong>
                                                </Col>
                                                <Col md={8}>
                                                    <strong>Material Name</strong>
                                                </Col>
                                            </Row>
                                            {
                                                detailData?.attachment?.map((attachment) => {
                                                    return (
                                                        <Row>
                                                            <Col md={4}>
                                                                {
                                                                    attachment?.material_category_name
                                                                }
                                                            </Col>
                                                            <Col md={8}>
                                                                {attachment?.name}
                                                            </Col>

                                                        </Row>
                                                    )
                                                })
                                            }

                                        </div>

                                        <br></br>
                                    </>
                                }


                                <Divider style={{ color: '#212c7a' }}>Class Information</Divider>
                                {
                                    detailData?.instructor?.length > 0 && <Row className="mb-3">
                                        <Col className="gutter-row" span={5}>
                                            <h5>Facilitator/Assessor</h5>
                                        </Col>
                                        <Col className="gutter-row" span={18}>
                                            {
                                                detailData?.instructor?.map((v) => {
                                                    return v?.full_name
                                                })?.join(', ')
                                            }
                                        </Col>
                                    </Row>
                                }


                                <Row className="mb-3">
                                    <Col className="gutter-row" span={5}>
                                        <h5>Date & Time</h5>
                                    </Col>
                                    <Col span={18}>
                                        {detailData.classroom.class_time_format} WIB
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col className="gutter-row" span={5}>
                                        <h5>Classroom Type</h5>
                                    </Col>
                                    <Col className="gutter-row" span={18}>
                                        ONLINE
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col className="gutter-row" span={5}>
                                        <h5>Classroom</h5>
                                    </Col>
                                    <Col className="gutter-row" span={18}>
                                        <Tag color="#87d068">Google Classroom</Tag>
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col className="gutter-row" span={5}>
                                        <h5>Quota</h5>
                                    </Col>
                                    <Col className="gutter-row" span={8}>
                                        {/* {detailData.classroom.limit !== null ? detailData?.classroom?.limit + ' Participant' : 'Unlimited'} <strong>{detailData.classroom.limit !== null ? '(' + (parseInt(detailData?.classroom?.limit ? detailData?.classroom?.limit  : 0) - parseInt(detailData?.participant?.length)) + ' Tersisa)' : ''}</strong> */}
                                        {
                                            detailData.classroom.limit ? `${detailData?.classroom?.limit} Pericipant` : 'Unlimited'
                                        }

                                    </Col>
                                </Row>


                                <Row>

                                    <Col style={{ marginBottom: '20px', marginTop: '30px' }} span={6} offset={16}>
                                        {!detailData?.classroom?.is_registered ?
                                            detailData.classroom.is_enabled ? <Button onClick={(e) => {
                                                if (parseInt(detailData?.classroom?.is_payment) == 1) {
                                                    setShowDialogPayment(true)
                                                } else {
                                                    showConfirmJoinClass(detailData.classroom.id)
                                                }
                                            }} type="primary" block size={'large'}>Register Now</Button> : 'This Class Currently Unavailable / Expired'
                                            : <h4>ALREADY REGISTERED THIS CLASS</h4>
                                        }


                                    </Col>
                                </Row>

                            </div>


                        </div>
                    }


                </Card>


            </>
        )
    }


    return (
        <>

            <CustomModal
                title={showDetail ? 'View Classroom' : 'List Classroom'}
                content={showDetail ? renderDetail() :
                    <Row className="mb-4">
                        <Col lg={24} xl={24}>
                            <Tabs defaultActiveKey="UPCOMING" items={[
                                {
                                    key: 'UPCOMING',
                                    label: `Upcoming Classroom`,
                                    children: renderList()
                                },
                                {
                                    key: 'HISTORY',
                                    label: `Previous Classroom`,
                                    children: renderList()
                                }
                            ]} onChange={(v) => {
                                setCurrentPage(1)
                                setTotalPage(0)

                                setDataSourceOptions(Object.assign(dataSourceOptions, {
                                    list_type: v,
                                    keywords: '',
                                    page: 1,
                                    limit: 8
                                }))
                                initializeData()
                            }} />
                        </Col>
                    </Row>}
                showBack={showDetail}
                onBack={() => {
                    setShowDetail(false)
                }}
                onClose={() => {
                    props.toogleSelected(null)
                }}
            />

        </>
    )
}